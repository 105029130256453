import React,{useEffect} from 'react';
import {connect} from "react-redux";
import {updateDataStore} from "src/actions/foundationActions";
import Container from 'react-bootstrap/Container';
import {determineScreenOrientation, loadNewMod} from "src/utilities/utilityFunctions";
import {URLTokenInterpreter} from 'src/utilities/URLTokenInterpreter';
import { IntlProvider } from 'react-intl';
import AppLocale from './lang';
import StylizedTheme from './StylizedTheme'
import {obtainWelcomeMod} from "src/containers/foundation/foundationFunctions";
import useWindowDimensions from "src/components/supporting/UseWindowsDimensions";
import store from 'src/store';
import URLParser from "./utilities/URLParser";
import MediaQueryTool from "./utilities/MediaQueryTool";
import {obtainWelcomeModFromServer} from "./services/APIServices";

const App = (props) =>{
    let stateData = store.getState();
    useEffect(()=>{
        URLTokenInterpreter(props);
        obtainWelcomeModFromServer();
        //eslint-disable-next-line
    },[]);

    const { height,width } = useWindowDimensions();

    useEffect(()=>{
        determineScreenOrientation(width,height);
    },[width,height]);

    useEffect(()=>{
        loadNewMod(stateData.appSettings.modalModName,(module)=> store.dispatch(updateDataStore({modalBaseModule:module.default})));
        //eslint-disable-next-line
    },[]);

    useEffect(()=>{
        loadNewMod(stateData.appSettings.loadingModuleName,(module)=> store.dispatch(updateDataStore({loadingModule:module.default})));
        //eslint-disable-next-line
    },[stateData.appSettings.loadingModuleName]);

    let modal = stateData.dataStore.modalBaseModule ? <stateData.dataStore.modalBaseModule triggerName="showDefaultModal"/> : null;
    let spinner = stateData.appSettings.showSpinner && stateData.dataStore.loadingModule ? <stateData.dataStore.loadingModule /> : null;

    let currWelcomeModName = obtainWelcomeMod();
    useEffect(()=>{
        if(currWelcomeModName) {
            loadNewMod(currWelcomeModName, (module) => store.dispatch(updateDataStore({welcomeModule: module.default})));
        }
        //eslint-disable-next-line
    },[currWelcomeModName]);

    let moduleShow = stateData.dataStore?.welcomeModule ? <stateData.dataStore.welcomeModule height={height} /> : null;
    const locale=stateData.appSettings.locale;
    const currentAppLocale = AppLocale[locale];
    return (
        <React.StrictMode>
        <StylizedTheme>
            <Container fluid id="appWrapper" style={{height:height,padding:"0px",maxHeight:height,width:"100vw",maxWidth:"100vw"}}>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}
                >

                        <URLParser/>
                        {spinner}
                        {modal}
                        {moduleShow}
                        <MediaQueryTool/>

                </IntlProvider>
            </Container>
        </StylizedTheme>
        </React.StrictMode>
    )
};

const mapStateToProps = (state) =>{
    return {
        appSettings:state.appSettings,
        user:state.user,
        dataStore:state.dataStore
    };
};

export default connect(mapStateToProps,{})(App);
