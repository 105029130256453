export const updateAppSettings = (status) =>{
    return {
        type:"UPDATE_APP_SETTING",
        payload:status
    }
};

export const addControlSet = (controls) => {
    return {
        type:"ADD_CONTROLS",
        payload:controls
    }
};

export const updateDataStore = (status) => {
    return {
        type:"UPDATE_DATA_STORE",
        payload:status
    }
};

export const addTabGroup = (tabGroupData)=>{
    return {
        type:"ADD_TAB_GROUP",
        payload:tabGroupData
    }
};

export const removeTabGroup = (tabGroupName)=>{
    return {
        type:"REMOVE_TAB_GROUP",
        payload:tabGroupName
    }
};

export const addTabToGroup = (tabData) => {
    return {
        type:"ADD_TAB_TO_GROUP",
        payload:tabData
    }
};

export const removeTabFromGroup = (groupName,keyName) => {
    return {
        type:"REMOVE_TAB_FROM_GROUP",
        payload: {groupName:groupName,keyName:keyName}
    }
};

export const toggleTab = (groupName,key) => {
    return {
        type:"TOGGLE_TAB",
        payload:{groupName:groupName,key:key}
    }
};
