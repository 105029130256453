import {appSettingModel} from "../datamodels/foundation";
import {createDictionary} from "../utilities/utilityFunctions";

const appSettingsReducer = (state=appSettingModel(),action) => {
    if (action.type === 'UPDATE_APP_SETTING') {
        return  Object.assign({}, state, action.payload);
    }
    //if(action.type==="LOGOUT_USER"){
    //    return Object.assign({},appSettingModel(),{
    //        breakpoint:state.breakpoint,
    //        welcomeModName:state.welcomeModName,
    //     });
    //}
    return state;
};

const dataStoreReducer = (state = {},action)=>{
    if(action.type==='UPDATE_DATA_STORE'){
        return Object.assign({},state,action.payload);
    }
    if(action.type==="ADD_CONTROLS"){
        let allControls = Object.assign({},state.mediaControls,action.payload);
        return Object.assign({},state,{mediaControls:allControls});

    }
    //if(action.type==="LOGOUT_USER"){
    //    return Object.assign({},{loadingModule:state.loadingModule,welcomeModule:state.welcomeModule,appMainModule:state.appMainModule});
    //}
    return state;
};

const tabExists = (tabGroup,item) =>{
    let tabFilter = tabGroup.filter((tab)=>{
        return tab?.key===item.payload.key;
    });
    return tabFilter.length;
};

const tabsReducer = (state={dictionary:{}},action)=>{
    if(action.type==="ADD_TAB_TO_GROUP"){
        let newState = (state.hasOwnProperty(action.payload.groupName)
            ? Object.assign({},state)
            : Object.assign({},state,{[action.payload.groupName]:[]})
        );
        if(!tabExists(newState[action.payload.groupName],action)){newState[action.payload.groupName].push(action.payload)}
        newState.dictionary[action.payload.groupName]=createDictionary(newState[action.payload.groupName],"key");
        return newState;
    }

    if(action.type==="REMOVE_TAB_FROM_GROUP"){
        let newState = Object.assign({},state);
        newState[action.payload.groupName] = newState[action.payload.groupName].filter((item)=>{
            return item.key !== action.payload.keyName;
        });
        newState.dictionary[action.payload.groupName] = createDictionary(newState[action.payload],"key");
        return newState;
    }

    if(action.type==="TOGGLE_TAB"){
        let tabs = state.hasOwnProperty(action.payload.groupName) ? [...state[action.payload.groupName]] : [];
        //eslint-disable-next-line
        tabs.map((item,idx)=>{
            if(item["key"]===action.payload.key){
                tabs[idx].panelVisible=true;
                tabs[idx].isActiveTab=true;
            }
            else{
                tabs[idx].panelVisible=false;
                tabs[idx].isActiveTab=false;
            }
        });

        let newDict = createDictionary(tabs,"key");
        let newState = Object.assign({},state);
        newState.dictionary[action.payload.groupName] = newDict;
        return newState;
    }

    return state
};

const tabGroupsReducer = (state={},action)=>{
    if(action.type==="ADD_TAB_GROUP"){
        return Object.assign({},state,{[action.payload.groupName]:action.payload});
    }
    if(action.type==="REMOVE_TAB_GROUP"){
        let newState = Object.assign({},state);
        delete newState[action.payload];
        return Object.assign({},newState);
    }
    return state;
};
/*
const controlsReducer = (state={},action)=>{
    if(action.type==="ADD_CONTROL_TO_GROUP"){

    }

    if(action.type==="REPLACE_CONTROL_GROUP"){

    }

    return state
};
*/

let exp = {
    appSettings:appSettingsReducer,
    dataStore:dataStoreReducer,
    tabStore:tabsReducer,
 //   controlStore:controlsReducer,
    tabGroups:tabGroupsReducer
};

export default exp;
