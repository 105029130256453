export const CneNurseAppSettingModel = () => {
    return {
        "apiURL":{
            "localhost":"http://cnenurseapi.local/api/v1",
            "dev.cne.purposefultechnologies.com":"https://cneapi.seeandplaymode.com/api/v1",
            "dev.mycebinder.com":"https://cneapi.seeandplaymode.com/api/v1",
            "mycebinder.com":"https://cneapi.seeandplaymode.com/api/v1",
            "www.mycebinder.com":"https://cneapi.seeandplaymode.com/api/v1",
            "cne.purposefultechnologies.com":"https://cneapi.seeandplaymode.com/api/v1"
        },
        "helpURL":{},
        "conversionURL":{
        },
        wsURL:{
        },
        contentURL:{
            "localhost":"https://d3uj7wv9x6s1az.cloudfront.net",
            "runmyteststest.local":"https://d3uj7wv9x6s1az.cloudfront.net",
            "runmytests.local":"https://d3uj7wv9x6s1az.cloudfront.net",
            "dev.mycebinder.com":"https://d3uj7wv9x6s1az.cloudfront.net",
            "mycebinder.com":"https://d3uj7wv9x6s1az.cloudfront.net",
            "www.mycebinder.com":"https://d3uj7wv9x6s1az.cloudfront.net"
        },
        "welcomeModName":"components/CneNurse/CNENurseWelcome",
        "welcomeModule":null,
        "showSpinner":false,
        "locale":"en",
        "defaultModule": "components/CneNurse/account/LoginForm",
        "defaultLoggedInModule": "components/CneNurse/LoggedInHome",
        "appMainModule":null,
        "appMainModuleName":"components/CneNurse/account/LoginForm",
        "logoSrc":null,
        "slogan":"",
        "localHosts":[
            "localhost",
            "127.0.0.1",
            "herearetests.local",
            "hereareteststest.local",
            "runmytests.local",
            "runmyteststest.local",
            "visualizationsite.local"
        ],
        "modalModName":"components/supporting/GenericModal/GenericModalDefault",
        "modalSideWrapper":null,
        "modalTopCloseButton":null,
        "loginFormName":"components/CneNurse/account/LoginForm",
        "sentryDSN":"https://396fc26685704c5abbf484ef051497ed@o425228.ingest.sentry.io/5895378",
        "domainCode":"cnenurse",
        "roomModule":"",
        "loadingModuleName":"components/CneNurse/site/Spinner",
        "modulebackground":"",
        "activityHeaderColor":"",
        "messages":{
            "emailNotUnique":"Hi. it looks like you've been here before. Can you please login",
            "emailUnique":"",
            "newAccountError":"There was an error Creating your account. Please try again.",
            "userDetailsSaved":"Saved!",
            "failDetailsSaved":"We had a problem saving your details.",
            "unknownToken":"Sorry. We couldn't figure out who was sending this!",
            "mediaNotSaved":"Whoops. We couldn't save that image."
        },
        "orgFilter":[],
        "allCertifications":[],
        "allOrganizations":[]
    }
};
