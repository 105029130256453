import React from 'react';
import HeartFill from "src/components/icons/HeartFill";
import HeartHalf from "src/components/icons/HeartHalf";
import Heart from "src/components/icons/Heart";

export const appSettingModel = () => {
    return {
        apiURL:{

        },
        storageURL:{
        },
        "helpURL":{},
		conversionURL:{
		},
        wsURL:{
        },
        contentURL:{
            "localhost":"",
            "runmyteststest.local":"",
            "runmytests.local":"",
        },
        URLStruct:{
        },
		"welcomeModName":"",
        "welcomeModule":null,
        "showSpinner":false,
        "locale":"en",
        "defaultModule": "",
        "defaultLoggedInModule": "",
        "appMainModule":null,
		"appMainModuleName":null,
		"logoSrc":null,
        "slogan":"",
        "localHosts":[
            "localhost",
            "127.0.0.1",
            "herearetests.local",
            "hereareteststest.local",
            "runmytests.local",
            "runmyteststest.local",
            "visualizationsite.local"
        ],
        "modalModName":"components/supporting/GenericModal/GenericModalDefault",
        "modalSideWrapper":null,
        "modalTopCloseButton":null,
        "loginFormName":"",
        "sentryDSN":"",
        "domainCode":"",
        "roomModule":"components/TDP/TDPRoom/TDPRoom",
        "loadingModuleName":"",
        "modulebackground":"",
        "activityHeaderColor":"",
        "messages":{
            "emailNotUnique":"It looks like you already have an account. Please login",
            "emailUnique":"",
            "newAccountError":"There was an error Creating your account. Please try again."
        }
    }
};

export const createTabModel = (label,groupName,key,tabVisible,panelVisible,tabStyle,className)=>{
    return {
        label: label,
        groupName: groupName,
        key: key,
        tabVisible: tabVisible,
        panelVisible:panelVisible,
        tabStyle:tabStyle,
        tabClass:className,
        isActiveTab:false
    }
};

export const createTabGroupObject = (groupName="",groupTabClassName="",groupTabStyle="",groupPanelClassName="",groupPanelStyle="",groupTabActiveClassName="") => {
    return {
        groupName:groupName,
        groupTabClassName:groupTabClassName,
        groupTabStyle:groupTabStyle,
        groupPanelClassName:groupPanelClassName,
        groupPanelStyle:groupPanelStyle,
        groupTabActiveClassName:groupTabActiveClassName

    }
};

export const createTabPanel = (groupName,key,content) => {
    return {
        groupName: groupName,
            key: key,
        content: content
    }
}

export const preloadArticles = [
    //{slug:"tdp-backstory",key:"backstory"},
];

export const ratingIcons = {
    "1":<HeartFill />,
    "0":<HeartHalf/>,
    "-1":<Heart/>
};
