import {combineReducers, compose,applyMiddleware} from 'redux';
import foundationReducers from './foundationReducers';

import Thunk from 'redux-thunk';
import testingReducers from './testingReducers';
import CneNurseReducers from "./CneNurseReducers";
import gameTimeReducers from "./gameTimeReducers";
import permissionsReducers from "./permissionsReducers";

const allReducers=Object.assign({},
    CneNurseReducers,
    foundationReducers,
    gameTimeReducers,
    permissionsReducers,
    testingReducers
    );
compose(applyMiddleware(Thunk));
export default combineReducers(allReducers);
