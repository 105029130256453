import axios from "axios";
import {apiURL} from "../utilities/utilityFunctions";
import {appSettingModel} from "../datamodels/foundation";
import {updateAppSettings} from "../actions/foundationActions";
import store from 'src/store';

export const registerHit =() => {

    axios.get(apiURL() + "/gametime/visit").then((evt)=>{}).catch(()=>{});
};

export const decodeToken = (token, callBack, fail) => {
    axios.get(apiURL() + "/invitations/decode/" + token,{headers:{
            "DomainCode":appSettingModel().domainCode,
            "OffsetTime":(new Date()).getTimezoneOffset()
        }}).then((evt) => callBack(evt)).catch((evt) => fail(evt));
};
/*
export const obtainContent = (slug,callBack,fail) => {
    axios.get(helpURL() + slug + ".json?" + randNum(1,99)).then((evt) => callBack(evt)).catch((evt) => fail(evt));
};
*/
export const obtainContent = (slug,callBack,fail) => {
    axios.get(apiURL() + "/cne/helpitem/" + slug).then((evt) => callBack(evt)).catch((evt) => fail(evt));
    //axios.get(helpURL() + slug + ".json?" + randNum(1,99)).then((evt) => callBack(evt)).catch((evt) => fail(evt));
};

export const obtainWelcomeModFromServer = () => {
    axios.get("/welcomemod.json").then((evt) => {
        console.dir(evt.data)
        store.dispatch(updateAppSettings({welcomeModName:evt.data.welcomemod}))
    }).catch((evt) => console.dir(evt));
}
