export const gameTimeDataModel = () => {
    return {
        "activityWidth": 6,
        "chatWidth": 6,
        "currentActivity": null,
        "currentActivity2":null,
        "hideTopNav":false,
        "myRooms":[],
        "myInvitedRooms":[],
        "myActivities":[],
        "activityDict":{},
        "mediaControls":{},
        "videoFormat":"tiled",
        "myActivitiesDict":{},
        "myLists":[],
        "myListDict":{},
        "myRoomDict":{},
        "mySettings":[],
        "mySettingsDict":{},
        "myMedia":[],
        "myMediaDict":{},
        "automaticallyAddTeams":false,
        "automaticallyAddEveryone":true
    }
};
/*
export const gameTimeSession= () => {
    return {
        "emceeSocket": [],
        "hostSocket": 0,
        "teams": {},
        "host": {
            "name": ""
        }
    }
};
*/
export const currentActivityDataModel = () => {
    return {
        teams: {},
        hostSocket: 0,
        emceeSocket: [],
        "currentActivityName": null,
        "currentActivityName2": "components/gameTime/VideoMeetingRoot",
        "gameMode":"solo"
    }
};

export const networkGuest = (clientid="",displayName="",time=Date.now()) => {
    return {
        "clientid": clientid,
        "displayName": displayName,
        "time": time,
		"networkIdentity":""
    }
};

export const smallScreenSizes=["xs","sm","md"];
