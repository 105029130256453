import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import App from './App';
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import store from './store'
//import 'react-sortable-tree/style.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing  } from "@sentry/tracing";
import {CneNurseAppSettingModel} from "./datamodels/CneNurse";

let senDsn = CneNurseAppSettingModel()["sentryDSN"];
if(senDsn) {
    Sentry.init({
        dsn: senDsn,
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

let localSites = CneNurseAppSettingModel()["localHosts"];
const correctLocation = () =>{
    //eslint-disable-next-line
    if(location.protocol.substr(0,5)!=='https' && localSites.indexOf(location.hostname) ===-1){
        //eslint-disable-next-line
        location.href="https://" + location.hostname + ':' + location.port;
    }
};

correctLocation();
    ReactDOM.render(
        <Provider store={store}>
            <DndProvider backend={Backend}>
                <App/>
            </DndProvider>
        </Provider>, document.getElementById('root'));
