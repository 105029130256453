import {gameTimeDataModel} from '../datamodels/gameTime';
import {currentActivityDataModel} from "../datamodels/gameTime";

const gameTimeReducer = (state=gameTimeDataModel(),action) => {
    if (action.type === 'UPDATE_GAME_TIME') {
        return  Object.assign({}, state, action.payload);
    }
    if(action.type==="ADD_MEDIA_TO_DICT"){
        if(action.payload.hasOwnProperty("mymediaid")){
            let newDict = Object.assign({},state.myMediaDict,{[action.payload.mymediaid]:action.payload});
            return Object.assign({},state,{myMediaDict:newDict});
        }
    }

    //if(action.type==="LOGOUT_USER"){
    //    return gameTimeDataModel();
    //}
    return state;
};


export const potentialResponsesReducer = (state=[],action) => {
    if(action.type==='ADD_RESPONSE_TO_BE_PROCESSED'){
        let newState=[...state];
        newState.push(action.payload);
        return newState;
    }

    if(action.type==='REPLACE_POTENTIAL_RESPONSES'){
        return action.payload;
    }
    //if(action.type==="LOGOUT_USER"){
    //    return [];
    //}

    return state;
};


//is this used?
let initialHostData ={name:"",socket:""};
export const hostDataReducer = (state=initialHostData,action) =>{
    if(action.type==='HOST_INFO'){
        return Object.assign({},state,action.payload);
        //return newState;
    }
    //if(action.type==="LOGOUT_USER"){
     //   return initialHostData;
    //}
    return state;
};

export const networkGuestsReducer=(state={},action) =>{
    if(action.type==='WEBSOCKET_UPDATE' || action.type==='WS_HERE_I_AM'){
        let allGuests = Object.assign({},state);
        allGuests[action.payload.clientid] = action.payload;
        return Object.assign({},allGuests);
    }

    if(action.type==='REMOVE_NETWORK_GUEST'){
        let allGuests = Object.assign({},state);
        delete allGuests[action.payload];
        return Object.assign({},allGuests);
    }
    //if(action.type==="LOGOUT_USER"){
    //    return {};
    //}
    return state;
};


export const webSocketUpdateReducer=(state={},action) =>{
  if(action.type==='WEBSOCKET_UPDATE'){
       return Object.assign({},state,action.payload);
  }
    //if(action.type==="LOGOUT_USER"){
    //    return {};
    //}
  return state;
};


//Merges currentActivityData and
export const currentActivityDataReducer = (state=currentActivityDataModel(),action) => {
    if(action.type==='UPDATE_CURRENT_ACTIVITY_DATA'){
        return Object.assign({},state,action.payload);
    }

    if(action.type==="UPDATE_CURRENT_ACTIVITY_DATA_TEAMS"){
        let newState=Object.assign({},JSON.parse(JSON.stringify(state)));
        newState.teams[action.payload.teamid] = action.payload;
        return newState;
    }

    if(action.type==="REPLACE_CURRENT_ACTIVITY_DATA"){
        return Object.assign({},action.payload);
    }

    if(action.type==="ADD_ACTIVITY_DATA_FIELD"){
        let dataFields = Array.isArray(state.activityDataFieldList) ? [...state.activityDataFieldList] : [];
        //eslint-disable-next-line
        action.payload.map((fieldName)=>{
            if(dataFields.indexOf(fieldName) ===-1){
                dataFields.push((fieldName));
            }
        });
        return Object.assign({},state,{activityDataFieldList:dataFields});
    }


    //if(action.type==="LOGOUT_USER"){
    //    return currentActivityDataModel();
    //}
    return state;
};
/*
const teamAlreadyAdded = (team,waitingTeams) => {
    return waitingTeams.hasOwnProperty(team.teamid)
};
*/




export const activityResponse = (state={},action) => {

    if(action.type ==='CLEAR_ACTIVITY_RESPONSE'){
        return [];
    }
    if(action.type==='ACTIVITY_RESPONSE'){
        return Object.assign({},state,action.payload);
    }
    //if(action.type==="LOGOUT_USER"){
    //    return {};
    //}
    return state;
};

export const gameState = (state={},action) => {
    if(action.type==="UPDATE_GAME_STATE"){
        return Object.assign({},state,action.payload)
    }
    if(action.type==="UPDATE_GAME_STATE_TEAMS"){
        let teams = state.teams ? state.teams : {};
        teams[action.payload.teamid] = action.payload;
        return Object.assign({},state,{teams:teams});
    }

    if(action.type==="REPLACE_GAME_STATE_TEAMS"){
        return Object.assign({},state,{teams:action.payload});
    }

    if(action.type==="RESET_GAME_STATE"){
        return action.payload;
    }
    return state;

};



const reducer = {
    gameTime:gameTimeReducer,
    currentActivityData:currentActivityDataReducer,
    potentialResponses:potentialResponsesReducer,
    networkGuests:networkGuestsReducer,
    webSocketUpdateRequests:webSocketUpdateReducer,
    activityResponse:activityResponse,
    gameState:gameState

};

export default reducer






/*
export const gTSessionReducer = (state=gameTimeSession(),action) =>{
    if(action.type==='UPDATE_GAMETIME_SESSION'){
        return Object.assign({},state,action.payload);
    }



    if(action.type==='UPDATE_TEAMMEMBER'){
        let newTeamMembers = Object.assign({},state);
        if(!newTeamMembers.team.members){ newTeamMembers.team.members=[]}
        newTeamMembers.team.members.push(action.payload.name);
        return newTeamMembers;
    }

    return state;
};
*/

/*
export const teamDataReducer = (state = {},action) =>{
    if(action.type==='UPDATE_TEAMDATA'){
        return Object.assign({},state,action.payload);
        //newTeams[action.payload.teamid]=action.payload;
        //return newTeams;
    }

    if(action.type==='UPDATE_TEAMMEMBER'){
        let newTeamMembers = Object.assign({},state);
        if(!newTeamMembers.team.members){ newTeamMembers.team.members=[]}
        newTeamMembers.team.members.push(action.payload.name);
        return newTeamMembers;
    }

    if(action.type==="LOGOUT_USER"){
        return {};
    }

    return state;
};
 */
