const CneNurseReducer = (state= {},action) => {
    if (action.type === 'UPDATE_CNE_NURSE') {
        return  Object.assign({}, state, action.payload);
    }

    return state;
};

let exp = {
    cneNurse:CneNurseReducer
};

export default exp;
