import React from 'react';
import axios from 'axios';
import {
    apiURL,
    obtainURLParam,
    isLoggedIn,
    showSpinner,
    hideSpinner,
    makeAppTopMod
} from "src/utilities/utilityFunctions";
import store from 'src/store';
import {updateAppSettings} from "src/actions/foundationActions";
import {obtainContent} from "src/services/APIServices";
import {updateDataStore} from "src/actions/foundationActions";
import TabPanel from "../../components/supporting/TabPanel";

export const defaultStyle = {height:"100%",maxHeight:"100%",maxWidth:"100%",width:"100%"};

export const checkConnection = () => {
    axios.get(apiURL() + "/KimRoot/testConnection").then((evt)=>processConnection(evt)).catch((evt)=>failConnection(evt));
};

export const failConnection= ()=> {
    store.dispatch(updateAppSettings({connectionLive:false}));
};

export const processConnection = (evt) => {
    if (evt.data.statusCode===200) {
        store.dispatch(updateAppSettings({connectionLive:evt.data.data}));
    }
};

let connectionTimer=null;

export const createConnectionTimer = (checkStatusFunc) => {
    checkStatusFunc = checkStatusFunc ? checkStatusFunc : checkStatus;
    if(!connectionTimer){checkStatusFunc()}
};

export const checkStatus = (delay=30000,checkConnectionFunc) => {
    checkConnectionFunc = checkConnectionFunc ? checkConnectionFunc : checkConnection;
    checkConnectionFunc();
    clearInterval(connectionTimer);
    connectionTimer = setInterval(()=>{
        checkConnectionFunc();
    },delay)
};

//The top module for an app is the "welcomeMod" which loads into App
export const obtainWelcomeMod = () => {
    let stateData = store.getState();
    return (
        obtainURLParam("randLoad")
            ? "components/Testing/RandomLoad"
            : stateData.appSettings?.welcomeModName
                ? stateData.appSettings.welcomeModName
                : 'KIM/Welcome'
    );
};

//This is meant to remove any keys in state which can not be serialized before outputting to the screen for testing
export const cleanNewState = (newState) => {
    let toClean = Object.assign({}, newState);
    delete toClean["dataStore"];
    delete toClean["simplewebrtc"];
    if (toClean.simplewebrtc?.api?.signalingClient?.xmpp?.transports?.websocket) {
        delete toClean.simplewebrtc.api.signalingClient.xmpp.transports.websocket;
    }
    return toClean;
};

export const showTestData = (props) => {

    if (props.appSettings.testMode) {
        let newState = Object.assign({}, props.state);


        let output = 'Can not stringify state';
        try {
            output = JSON.stringify(cleanNewState(newState));
        } catch (err) {
            console.dir(err);
        }
        return <div id="testData">{output}</div>
    }
};
/*
export const moduleShow = (props) => {return props.dataStore.appMainModule ? <props.dataStore.appMainModule /> : <span>Welcome</span>;};

export const currMainMod = (props) => {
    return props.appSettings.appMainModuleName
        ? props.appSettings.appMainModuleName
        : isLoggedIn()
            ? props.appSettings.defaultLoggedInModule
            : props.appSettings.defaultModule
};
*/
export const returnHeightOf = (id) => {
    let elem=document.getElementById(id);
    if(elem) {
        let style = elem?.currentStyle || window.getComputedStyle(elem);
        return (style
            ? parseInt(style.height) +
            parseInt(style.marginTop) +
            parseInt(style.marginBottom) +
            parseInt(style.borderTopWidth) +
            parseInt(style.borderBottomWidth) +
            parseInt(style.paddingBottom) +
            parseInt(style.paddingTop)
            : 0);
    }
    return 0;
};

export const returnBoundingRectOf = (id) =>{
    let elem=document.getElementById(id);
    return elem ? elem.getBoundingClientRect() : {};
};

export const returnWidthOf = (id) => {
    return document.getElementById(id) ? document.getElementById(id).offsetWidth : 0;
};

export const fillRemainderOfHeight = (id) => {
    //let footerHeight = document.getElementById("CneFooter").style.height;
    let elmt = document.getElementById(id);
    elmt.style.height = window.innerHeight - elmt.getBoundingClientRect().top - 25;
};

export const goHome = (makeAppTopModFunc) => {
    makeAppTopModFunc = makeAppTopModFunc ? makeAppTopModFunc : makeAppTopMod;
    let comp = isLoggedIn() ? store.getState().appSettings.defaultLoggedInModule : store.getState().appSettings.defaultModule;
   store.dispatch(updateAppSettings({hideTopNav:false}));
    makeAppTopModFunc(comp);
};
/*
export const preLoadText = (obtainBlurbFunc) => {
    obtainBlurbFunc = obtainBlurbFunc ? obtainBlurbFunc : obtainBlurb
    //eslint-disable-next-line
    preloadArticles.map((item)=>{
        obtainBlurbFunc(item.slug,item.key);
    });
};*/

export const obtainBlurb = (slug,key,showSpinnerFunc,obtainContentFunc,processContentFunc,failContentFunc) => {
    showSpinnerFunc = showSpinnerFunc ? showSpinnerFunc : showSpinner;
    obtainContentFunc = obtainContentFunc ? obtainContentFunc : obtainContent;
    //processContentFunc = processContentFunc ? processContentFunc : processContent;
    //failContentFunc =failContentFunc ? failContentFunc : failContent;

    showSpinnerFunc();
    obtainContentFunc(slug,(evt)=>processContent(key,evt),(evt)=>failContent(key,evt));
};

export const processContent = (key,evt,hideSpinnerFunc)=>{
    hideSpinnerFunc =hideSpinnerFunc ? hideSpinnerFunc : hideSpinner;
    hideSpinnerFunc();
    store.dispatch(updateDataStore({[key]:evt.data}));
};

export const failContent = (key,evt,hideSpinnerFunc)=>{
    hideSpinnerFunc =hideSpinnerFunc ? hideSpinnerFunc : hideSpinner;
    hideSpinnerFunc();
};

export const removeAllChildren = (id) => {
    let parent = document.getElementById(id);
    while (parent.firstChild) {
        parent.removeChild(parent.firstChild);
    }
};

export const renderPanels = (panelDetails,processedStyle,height)=>{
    let storeData = store.getState();
    return panelDetails.map((item)=>{
        let tabDetails = (storeData.tabStore?.dictionary[item.groupName] && storeData.tabStore.dictionary[item.groupName][item.key]
            ? storeData.tabStore.dictionary[item.groupName][item.key]
            : {});
        return tabDetails && tabDetails.panelVisible ? <TabPanel details={item} style={Object.assign({},defaultStyle,processedStyle)} height={height} key={item.key}/> : null;
    });
};

//export const obtainTabDetails = (groupName) => {

//}

export const obtainTabGroup = (groupName) => {
    let stateData = store.getState();
    return (stateData.tabGroups.hasOwnProperty(groupName)
        ? stateData.tabGroups[groupName]
            : {}
    )
}
